import React, {useState, useEffect} from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  mergePosts,
  buildCanonical
} from '../lib/helpers'
import {loadNextPageByCategory} from '../lib/api'
import InfiniteScroll from 'react-infinite-scroller'
import BlogPostPreview from '../components/post/preview'
import GraphQLErrorList from '../components/graphql/error'
import SEO from '../components/seo'
import Layout from '../components/layout'

export const query = graphql`
  query($id: String!) {
    category: sanityCategory(_id: {eq: $id}) {
      _id
      title
      description
      slug {
        current
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { categories: { elemMatch: { _id: {eq: $id} } } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            alt
            asset {
              _id
              url
              fluid(maxWidth: 360, maxHeight: 202) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
          authors {
            _key
            author {
              image {
                alt
                asset {
                  fluid(maxWidth: 48, maxHeight: 48) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              name
              slug {
                current
              }
            }
          }
          _rawCategories(resolveReferences: {maxDepth: 1000})
        }
      }
    }
  }
`

const CategoryTemplate = props => {
  const {data, errors} = props
  const postsPerPage = 6
  const [infinite, setInfinite] = useState({loading: false, page: 1, loadMore: false, hasMore: true})

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const category = (data || {}).category
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  const [posts, setPosts] = useState(postNodes)

  const loadNewPosts = () => {
    if (!infinite.loading && infinite.hasMore) {
      setInfinite({...infinite, loading: true, loadMore: true})
    }
  }

  useEffect(() => {
    if (infinite.loadMore && infinite.hasMore) {
      const startIndex = infinite.page * postsPerPage
      const endIndex = (startIndex + postsPerPage) - 1

      loadNextPageByCategory(startIndex, endIndex, category._id)
        .then(data => {
          const all = mergePosts(posts, data)
          setPosts(all)
          setInfinite({page: infinite.page + 1, loading: false, loadMore: false, hasMore: data.length > 0})
        })
        .catch((error) => {
          console.log(error)
          setInfinite({page: infinite.page, loading: false, loadMore: false, hasMore: false})
        })
    }
  })

  return (
    <Layout>
      <SEO
        canonical={buildCanonical(`/category/${category.slug.current}`)}
        title={category.title || 'Untitled'}
        description={category.description} />

      <section>
        <div className='relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8'>
          <div className='relative max-w-7xl mx-auto'>
            <div className='text-center'>
              <h1 className='text-3xl leading-9 tracking-tight font-raleway font-bold text-gray-900 sm:text-4xl sm:leading-10'>
                {category.title}
              </h1>
              {category.description && (
                <p className='mt-3 max-w-4xl mx-auto text-xl leading-7 text-blue-900 opacity-60 sm:mt-4'>{category.description}</p>
              )}
            </div>

            <InfiniteScroll
              className='mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none'
              pageStart={0}
              loadMore={() => loadNewPosts()}
              hasMore={!infinite.loading && infinite.hasMore}>
              {posts.map(post => <BlogPostPreview key={post.id} post={post} />)}
            </InfiniteScroll>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CategoryTemplate
