/* eslint-disable no-undef */
import {parseSanityPosts} from './helpers'
import {sanityProjectId, sanityProjectDataset} from './config'

/**
 * Default attributes to load in the pagination
 * @var array
 */
const attributes = ['_id', 'publishedAt', 'mainImage', 'title', 'excerpt', 'slug{current}', `authors[]{author-> {image {...},name,slug {current}}}`, `"categories": categories[]-> {"id": _id, title, slug}`]

/**
 * Sanity API URL
 *
 * @var string
 */
export const sanityURL = `https://${sanityProjectId}.api.sanity.io/v1/data/query/${sanityProjectDataset}`

/**
 * build a query
 *
 * @param {array} filters
 * @param {arrat} fields
 */
export const buildSanityQuery = (filters = [], fields = []) => {
  const pipes = filters.length ? filters.map(param => encodeURIComponent(param)).join('%7C') : '*'
  const attributes = fields.length ? fields.map(field => encodeURIComponent(field)).join('%2C') : '...'

  return `${sanityURL}?query=${pipes}{${attributes}}`
}

/**
 * load next page of posts
 * build a query like this
 * const query = `https://3f534m2o.api.sanity.io/v1/data/query/production?query=*|[_type == "post"]|order(publishedAt desc)|[${startIndex}..${endIndex}]{_id,publishedAt,mainImage,title,excerpt,slug{current},authors[]{author-> {image {...},name,slug {current}}},"categories": categories[]-> {"id": _id, title, slug}}`
 *
 * @param {int} startIndex
 * @param {int} endIndex
 * @return {Promise}
 */
export const loadNextPage = (startIndex, endIndex) => new Promise((resolve, reject) => {
  const filters = ['*', '[_type == "post"]', 'order(publishedAt desc)', `[${startIndex}..${endIndex}]`]
  const query = buildSanityQuery(filters, attributes)

  fetch(query)
    .then(response => response.json())
    .then(response => parseSanityPosts(response.result))
    .then(data => resolve(data))
    .catch(error => reject(error))
})

/**
 * load next page of posts by Category
 * build a query like this
 * const query = `https://3f534m2o.api.sanity.io/v1/data/query/production?query=*|[_type == "post"]|order(publishedAt desc)|[${startIndex}..${endIndex}]{_id,publishedAt,mainImage,title,excerpt,slug{current},authors[]{author-> {image {...},name,slug {current}}},"categories": categories[]-> {"id": _id, title, slug}}`
 *
 * @param {int} startIndex
 * @param {int} endIndex
 * @param {string} _id
 * @return {Promise}
 */
export const loadNextPageByCategory = (startIndex, endIndex, _id) => new Promise((resolve, reject) => {
  const filters = ['*', `[_type == "post" && *[_type == "category" && _id == "${_id}"][0]._id in categories[]._ref]`, 'order(publishedAt desc)', `[${startIndex}..${endIndex}]`]
  const query = buildSanityQuery(filters, attributes)

  fetch(query)
    .then(response => response.json())
    .then(response => parseSanityPosts(response.result))
    .then(data => resolve(data))
    .catch(error => reject(error))
})
/**
 * load next page of posts by Author
 * build a query like this
 * const query = `https://3f534m2o.api.sanity.io/v1/data/query/production?query=*|[_type == "post"]|order(publishedAt desc)|[${startIndex}..${endIndex}]{_id,publishedAt,mainImage,title,excerpt,slug{current},authors[]{author-> {image {...},name,slug {current}}},"categories": categories[]-> {"id": _id, title, slug}}`
 *
 * @param {int} startIndex
 * @param {int} endIndex
 * @param {string} _id
 * @return {Promise}
 */
export const loadNextPageByAuthor = (startIndex, endIndex, _id) => new Promise((resolve, reject) => {
  const filters = ['*', `[_type == "post" && *[_type == "author" && _id == "${_id}"][0]._id in authors[].author._ref]`, 'order(publishedAt desc)', `[${startIndex}..${endIndex}]`]
  const query = buildSanityQuery(filters, attributes)

  fetch(query)
    .then(response => response.json())
    .then(response => parseSanityPosts(response.result))
    .then(data => resolve(data))
    .catch(error => reject(error))
})
